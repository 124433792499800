.footer {
  background-color: var(--background);
  color: var(--light);
  text-align: center;
  padding: 1rem 0;
}

.footer.transparent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--light);
  text-align: center;
  z-index: 999;
  backdrop-filter: blur(10px);
}

.footer p {
  margin: 0;
}
