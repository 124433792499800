/* Navbar.css */

.navbar {
	top: 0;
	left: 0;
	width: 100%;
	padding: 1rem 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	transition: background-color 0.3s ease;
  box-sizing: border-box; /* Include padding in width calculation */
  overflow: hidden; /* Prevent horizontal scrolling */
}

/* Transparent Navbar */
.navbar.transparent {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 1rem 2rem;
	background-color: rgba(0, 0, 0, 0.1);
	color: var(--light);
	z-index: 1000;
	/* Ensure it stays on top */
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(10px);
}

/* Navbar container */
.navbar-container {
	width: 100%;
	max-width: 1200px;
	display: flex;
	justify-content: space-between;
	align-items: center;
  padding: 0 1rem; /* Prevent the container from overflowing the viewport */
}

/* Logo */
.logo {
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--light);
}

/* Navigation Links */
.nav-links {
	list-style: none;
	display: flex;
	gap: 2rem;
	margin: 0;
	padding: 0;
}

.nav-link {
	text-decoration: none;
	font-size: 1rem;
	font-weight: 500;
	color: var(--light);
	position: relative;
	/* For underline positioning */
	padding: 0.25rem 0;
}

/* Underline effect */
.nav-link::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 0;
	background-color: var(--accent1);
	transition: width 0.3s ease-in-out;
}

.nav-link:hover::after {
	width: 100%;
}

.nav-link:hover {
	color: var(--accent1);
}

/* Hamburger Menu */
.hamburger-menu {
  display: none; /* Hidden by default */
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 25px;
  margin-left: auto; /* Pushes it to the right in mobile view */
}

.hamburger-menu .bar {
  background-color: var(--light);
  height: 3px;
  width: 100%;
  margin: 2px 0;
  transition: all 0.3s ease;
}

/* Mobile Menu Styles */
.nav-links-mobile {
  position: fixed;
  top: 5.5rem;
  max-height: 0px;
  background-color: rgba(0, 0, 0, 0.95);
  display: none;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  align-items: flex-start;
  padding: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: max-height 0.3s ease;
  overflow: hidden;
  z-index: 1000;
}

.nav-links-mobile.open {
  max-height: 100vh;
}

.nav-links-mobile ul {
  padding: 0;
  margin: 0;
}

.nav-links-mobile li {
  margin: 1rem 0;
}
.nav-links-mobile a:hover {
  color: var(--accent1); /* Highlight on hover */
}


/* Responsive Media Query */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide links by default */
  }

  .hamburger-menu {
    display: flex; /* Show hamburger menu */
  }

  .nav-links-mobile {
    display: flex; /* Disable mobile menu */
  }
}
