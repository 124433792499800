/* index.css */

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--light);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

button, input, textarea {
  font-family: 'Poppins', sans-serif;
}


:root {
	--primary: #A3562F;
  --background: #2C2A28;
  --accent1: #D8B98A; 
  --accent2: #B04A5A;
  --neutral: #4B4B4B;
  --light: #F7EDE2;
}
