.about-container {
  padding: 2rem;
  background-color: #f5e8c7;
  color: #0a2742;
}


.about-container h2 {
    margin-bottom: 1rem;
  }

.about-container p {
    line-height: 1.6;
  }
