/* Home.css * /

/* Hero container that holds the intro sheet and main hero */
.hero-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.background-image-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Images */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease; /* Smooth transition for opacity and blur */
  z-index: 1; /* Ensure images stay below content */
}

/* Button Styles */
.hero-button {
  position: absolute;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: var(--accent1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0; /* Initially hidden */
}

.hero-button:hover {
  transform: scale(1.1);
}

/* Position Buttons */
.hero-button-left {
  left: 5%; /* Left side */
  top: 50%;
  transform: translateY(-50%);
  opacity: 1; /* Visible only when active */
}

.hero-button-center {
  bottom: 10%;
  transform: translateY(50%);
  opacity: 1; /* Visible only when active */
}

.hero-button-right {
  right: 5%; /* Right side */
  top: 50%;
  transform: translateY(-50%);
  opacity: 1; /* Visible only when active */
}

/* Intro sheet styles */
.intro-sheet {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 1;
	animation: fadeOut 1s forwards;
  animation-delay: 2.0s;
}

/* Content inside the intro sheet */
.intro-content {
  text-align: center;
  opacity: 0;
	animation: fadeIn 1s forwards;
  animation-delay: 0.5s;
}

.headphones-icon {
  font-size: 5rem;
  color: #FFFFFF; /* Accent color */
}

.intro-content p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #FFFFFF;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
	z-index: 100;
  text-align: center;
}

.hero-content h1 {
	font-size: 70px;
}

.title {
  transform: translateY(50px);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
}

.title.slide-fade-in {
  transform: translateY(0);
  opacity: 1;
}

.description {
  opacity: 0;
  transition: opacity 1s ease;
}

.description.fade-in {
  opacity: 1;
}


.background-image-container {
	z-index: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
