/* Team.css */
.team-container {
  padding: 2rem;
  background-color: var(--primary);
  color: var(--light);
  text-align: center;
}

.team-container h2 {
  margin-bottom: 1rem;
  color: var(--accent1);
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.team-member {
  background-color: var(--neutral);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  color: var(--light);
}

.team-member:hover {
  transform: scale(1.05);
  background-color: var(--primary);
}

.team-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  background-size: cover;
}

